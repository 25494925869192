import React from "react";
import counterpart from "counterpart";
import bind from "memoize-bind";
import Fa from "@generic/common/font-awesome";
import API from "@api";
import { Button } from "@shift-technology/shift-design-system";

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showForm: false,
            isFetching: false,
        };

        this.registerForm = this.registerForm.bind(this);
        this.toggleForm = this.toggleForm.bind(this);
        this.submit = this.submit.bind(this);
    }

    submit(event) {
        event.preventDefault();

        if (!this.state.isFetching) {
            const data = {};
            const inputs = this.form.querySelectorAll("input");
            for (const input of inputs) {
                data[input.name] = input.value;
            }

            this.setState({ isFetching: true });
            API.identityForgotPassword(data).then(() => {
                this.setState({ isFetching: false, emailSent: true }, () => {
                    setTimeout(() => {
                        this.setState({ emailSent: false });
                    }, 1000);
                });
            });
        }
    }

    toggleForm() {
        this.setState({ showForm: !this.state.showForm });
        this.props.toggleShowLogin();
    }

    registerForm(form) {
        this.form = form;

        if (this.form) {
            this.form.querySelector("input").focus();
        }
    }

    render() {
        const { emailSent, showForm } = this.state;

        return (
            <div className="forgot-password" data-st-test="forgot-password">
                {!emailSent && !showForm && (
                    <a className="forgot-link" onClick={bind(this.toggleForm, this)}>
                        {counterpart("account.forgot_password")}
                    </a>
                )}
                {!emailSent && showForm && (
                    <div>
                        <form onSubmit={this.submit} ref={this.registerForm}>
                            <input
                                type="email"
                                name="email"
                                data-st-test="email"
                                className="form-control"
                                placeholder={counterpart("account.email")}
                            />
                        </form>
                        <div className="button-container">
                            <Button
                                emphasis="secondary"
                                data-st-test="forgot-password-back"
                                onClick={bind(this.toggleForm, this)}
                            >
                                {counterpart("account.back")}
                            </Button>
                            <Button className="pull-right" data-st-test="forgot-password-submit" onClick={this.submit}>
                                {`${counterpart("account.send_email")}${this.state.isFetching ? " ..." : ""}`}
                            </Button>
                        </div>
                    </div>
                )}
                {emailSent && (
                    <strong data-st-test="email-sent">
                        <Fa name="check" /> {counterpart("account.email_sent")}
                    </strong>
                )}
            </div>
        );
    }
}

ForgotPassword.displayName = "ForgotPassword";

export default ForgotPassword;
