import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import counterpart from "counterpart";
import LabelItem from "@app/components/sidebar/label-item";
import LoadingButton from "@generic/buttons/loading-button";
import Fa from "@generic/common/font-awesome";
import API from "@api";
import ForgotPassword from "./forgot-password";

const lockoutTimeSpan = window.shiftContext.identity.lockoutOptions.defaultLockoutTimeSpanInMinute;

class LoginForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: [],
            showLogin: true,
        };

        this.submitLogin = this.submitLogin.bind(this);
        this.onLoginFailure = this.onLoginFailure.bind(this);
        this.onLoginSuccess = this.onLoginSuccess.bind(this);
        this.toggleShowLogin = this.toggleShowLogin.bind(this);
    }

    drawGraph(status) {
        if (typeof window.shiftDrawGraph === "function") {
            window.shiftDrawGraph(status);
        }
    }

    toggleShowLogin() {
        this.setState({ showLogin: !this.state.showLogin });
    }

    submitLogin() {
        const inputs = this.form.querySelectorAll("input");
        const formData = {
            rememberMe: false,
        };

        for (const input of inputs) {
            formData[input.name] = input.value;
        }

        this.setState({ errors: [] });
        return API.authenticationLogin(formData).then(
            (result) =>
                new Promise((resolve) => {
                    if (!result || result.state !== "changePassword") {
                        this.drawGraph("success");
                    }

                    resolve(result);
                }),
            (error) =>
                new Promise((resolve, reject) => {
                    this.drawGraph("error");
                    reject(error);
                })
        );
    }

    onLoginSuccess(res) {
        if (res && res.state === "changePassword") {
            this.props.history.push(`/resetPassword/${res.resetToken}/${res.userId}/1`);
        } else {
            if (res && res.state === "passwordExpiresSoon") {
                window.sessionStorage.setItem("showPasswordReminder", "true");
            }

            window.location.href = this.props.referrer;
        }
    }

    onLoginFailure(error) {
        this.setState({ isFetching: false, errors: error.response.errors });
    }

    translateError(error) {
        return counterpart(`common.errors.${error}`, {
            timespan: lockoutTimeSpan,
        });
    }

    render() {
        const helpEnable = true;

        return (
            <div className="login-form" data-st-test="login-form">
                {this.state.showLogin && (
                    <form
                        onSubmit={(e) => e.preventDefault()}
                        ref={(form) => {
                            this.form = form;
                        }}
                    >
                        <LabelItem label={counterpart("account.email")}>
                            <input
                                type="email"
                                name="email"
                                data-st-test="email"
                                className="form-control"
                                autoComplete="off"
                                autoFocus
                            />
                        </LabelItem>
                        <LabelItem label={counterpart("account.password")}>
                            <input
                                type="password"
                                name="password"
                                autoComplete="new-password"
                                data-st-test="password"
                                className="form-control"
                            />
                        </LabelItem>
                        <div className="form-group">
                            {this.state.errors.length !== 0 && (
                                <div className="errors">
                                    {this.state.errors.map((error, idx) => (
                                        <div data-st-test="login-error" className="alert alert-danger" key={idx}>
                                            <Fa name="times-circle" />
                                            {this.translateError(error)}
                                        </div>
                                    ))}
                                </div>
                            )}
                            <LoadingButton
                                type="submit"
                                className="login-button"
                                data-st-test="login"
                                loadingMsg="Login..."
                                onClick={this.submitLogin}
                                onFailure={this.onLoginFailure}
                                onSuccess={this.onLoginSuccess}
                            >
                                {counterpart("account.login")}
                            </LoadingButton>
                            {helpEnable && (
                                <div className="need-help" data-st-test="help-button">
                                    <Link to="/help" target="_blank">
                                        {counterpart("navbar.help")}
                                    </Link>
                                </div>
                            )}
                        </div>
                    </form>
                )}
                <ForgotPassword toggleShowLogin={this.toggleShowLogin} />
            </div>
        );
    }
}

LoginForm.displayName = "LoginForm";

LoginForm.propTypes = {
    history: PropTypes.object.isRequired,
};

export default withRouter(LoginForm);
